import React from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { taxRate } from "../../../config";

// Redux Form
import { change, submit as submitForm, formValueSelector, reduxForm } from "redux-form";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./SearchResults.css";
import cx from "classnames";
import { Row, Button, ButtonGroup } from "react-bootstrap";

// Component
import CustomPagination from "../CustomPagination";
import ListingItem from "../ListingItem";
import NoResults from "../NoResults";

import submit from "../SearchForm/submit";
import { FaTh, FaThList } from "react-icons/fa";
const isBrowser = typeof window !== "undefined";
const smallDevice = isBrowser ? window.matchMedia("(max-width: 1500px)").matches : undefined;
class SearchResults extends React.Component {
  static propTypes = {
    change: PropTypes.any,
    submitForm: PropTypes.any,
    results: PropTypes.array,
    currentPage: PropTypes.number,
    total: PropTypes.number,
    isResultLoading: PropTypes.bool,
  };

  static defaultProps = {
    results: [],
    //isResultLoading: false,
    showMap: false,
    showMapLoader: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      listViewType: smallDevice ? "tile" : "list",
    };
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { currentPage } = this.props;
    if (currentPage != undefined) {
      this.setState({ page: currentPage });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { currentPage } = nextProps;
    if (currentPage != undefined) {
      this.setState({ page: currentPage });
    }
  }

  async handlePagination(currenctPage, size) {
    const { change, submitForm } = this.props;
    await change("currentPage", currenctPage);

    await submitForm("SearchForm");
    window.scrollTo(0, 0);
  }

  setListViewType(type) {
    this.setState({ listViewType: type });
  }
  render() {
    const { page, listViewType } = this.state;
    const { results, total, isResultLoading, showMap, showMapLoader, guests } = this.props;
    
    let itemViewClass = listViewType === "list" ? cx(s.listItemList) : cx(s.listItemCard, s.displayInlineBlock);
    // PASCAL TEMP CHANGE
    if (results != null && results.length > 0) {
      return (
        <div>
          {!smallDevice && (
            <ButtonGroup className={s.viewSwitch}>
              <Button className={listViewType === "list" ? s.active : ""} onClick={() => this.setListViewType("list")}>
                <FaThList />
                Liste
              </Button>
              <Button className={listViewType !== "list" ? s.active : ""} onClick={() => this.setListViewType("card")}>
                <FaTh />
                Kacheln
              </Button>
            </ButtonGroup>
          )}

          <div className={cx(s.searchResults, { [s.listItemOnlyCard]: showMap == false })}>
            {!showMapLoader && (
              <Row className={s.noMargin}>
                <div className={cx(s.resultsContainer)}>
                  {/* <Loader
                    type={"page"}
                    show={isResultLoading}
                  /> */}
                  {isResultLoading && <div className={s.loadingOverlay} />}
                  {results.map((item, listIndex) => {
                    return (
                      <div className={itemViewClass} key={item.id}>
                        <ListingItem
                          id={item.id}
                          basePrice={item.listingData.basePrice}
                          currency={item.listingData.currency}
                          title={item.title}
                          beds={item.beds}
                          city={item.city}
                          state={item.state}
                          personCapacity={item.personCapacity}
                          roomType={
                            item.settingsData[0].listsettings ? item.settingsData[0].listsettings.itemName :  "Lager- & Logistikfläche"
                          }
                          coverPhoto={item.coverPhoto}
                          listPhotos={item.listPhotos}
                          bookingType={item.bookingType}
                          reviewsCount={item.reviewsCount}
                          reviewsStarRating={item.reviewsStarRating}
                          wishListStatus={item.wishListStatus}
                          isListOwner={item.isListOwner}
                          personCount={guests}
                          isInsurable={item.securityClass && item.securityClass !== "SK0"}
                          qmPrice={Number(item.listingData.qmPrice)}
                          nkPrice={Number(item.listingData.nkPrice)}
                          taxRate={taxRate}
                          services={item.listingData.services}
                          isListView={listViewType === "list"}
                        />
                      </div>
                    );
                  })}
                  {listViewType === "list" && (
                    <div className={itemViewClass}>
                      <ListingItem isAdditionalItem={true} />
                    </div>
                  )}
                </div>
                <div className={s.resultsFooter}>
                  <div className={s.resultsPagination}>
                    <div className={s.pagination}>
                      <CustomPagination total={total} current={page} defaultCurrenct={1} defaultPageSize={12} handleChange={this.handlePagination} />
                    </div>
                  </div>
                </div>
              </Row>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {isResultLoading && <div className={s.loadingOverlay} />}
          <NoResults />
        </div>
      );
    }
  }
}

SearchResults = reduxForm({
  form: "SearchForm", // a unique name for this form
  onSubmit: submit,
  destroyOnUnmount: false,
})(SearchResults);

const selector = formValueSelector("SearchForm");

const mapState = (state) => ({
  results: state.search.data,
  currentPage: selector(state, "currentPage"),
  total: state.search.count,
  isResultLoading: state.search.isResultLoading,
  showMap: state.personalized.showMap,
  showMapLoader: state.loader.showMapLoading,
  guests: Number(selector(state, "personCapacity")),
});

const mapDispatch = {
  change,
  submitForm,
};

export default withStyles(s)(connect(mapState, mapDispatch)(SearchResults));
